<template>
    <section id="feed">
        <feed>
            <v-overlay v-if="loading == true" :loading="loading" transition="fade-transition">
                <v-progress-circular :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
            </v-overlay>
            <slot v-show="loaded" />
        </feed>
    </section>
</template>

<script>
export default {
    name: "HomeAbout",

    components: {
        Feed: () => import("@/components/Feed"),
    },
    data: () => ({
        loading: true,
        loaded: false,
    }),
    mounted() {
        const readyHandler = () => {
            if (document.readyState == "complete") {
                this.loading = false;
                this.loaded = true;
                document.removeEventListener("readystatechange", readyHandler);
            }
        };

        document.addEventListener("readystatechange", readyHandler);

        readyHandler(); // in case the component has been instantiated lately after loading
    },
};
</script>
